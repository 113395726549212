/* ... Your existing CSS ... */

/* Add the following at the end of your CSS file */

.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
}

/* For medium-sized screens (tablets, etc.) */
@media screen and (max-width: 850px) {
    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For small screens (mobile devices) */
@media screen and (max-width: 415px) {
    .projects-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}


.sub-item {
    /* background-color: #f9f9f9; Change this to any background color you like */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional box-shadow */
    display: flex;
    flex-direction: column;
}

.project-header {
    display: flex;
    justify-content: space-between;
    /* Distributes the title and link apart */
    margin-bottom: 10px;
    /* Optional margin between header and description */
}

.project-link {
    display: inline-flex;
    align-items: center;
}

.project-link-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.view-all-container {
    text-align: center;
    margin-top: 30px;
}
.archive-link {

    text-decoration: none;
    color: var(--green);
    margin-top: 40px;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: var(--font-mono);
    border: 2px solid var(--green);
    transition: background-color 0.3s ease;
}



.archive-link:hover{
    background-color: rgba(100, 255, 218, 0.1);
}