.section-title {
    font-size: 80px;
    font-weight: bold;
    color: var(--lightest-slate);
    margin-bottom: 2%;
    margin-top: 3%;
    word-wrap: break-word;
}

.section-subtitle {
    font-size: 45px;
    font-weight: 500;
    color: var(--light-slate);
    padding-top: 0px;
    margin : 0px 0px 0px 0px;
}

.section-details {
    font-size: 16px;
    font-weight: 400;
    padding-right: 25%;
    color: var(--slate);
    margin-bottom: 40px;
}

/* Align content to the left and stack it vertically */
.section-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 0%;
}
#about {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 5vh;
    justify-content: center; /* Vertically center the content */
    height:90vh; /* Optional: Set the height of the #about div to 100% of the viewport height */
    padding-left: 9%;
    padding-right: 9%;

}


.about-content {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    flex-wrap: wrap; /* Allow the content to wrap to a new line */
}

.text-content {
    display: flex;
    flex-direction: column;
    flex: 1; /* Give text-content flexible width */
}
/* 
.pgp-key-link{
    visibility: hidden;
} */

.pgp-key {
    width: 20vw;
    height: 20vw;
    background-color: var(--navy);
    font-family: 'Roboto Mono', monospace;
    font-size: 1rem;
    margin-right: 0px;
    margin-top: 15px; 
    text-align: center;
    border: 2px solid var(--green);
    border-radius: 10px;
    padding: 1.4em; /* Set uniform padding */
    align-self: center;
    transition: all 0.8s ease-in-out;
    box-sizing: border-box;
    flex-basis: 20vw;
    min-width: 200px;
    min-height: 200px;
    word-break: break-all;
    overflow: hidden; /* Hide the overflow */
    display: -webkit-box; /* Use a webkit box model */
    -webkit-box-orient: vertical; /* Orient the content vertically */
    -webkit-line-clamp: 4; /* Display N number of lines, and add ellipsis after that */
    position: relative;
}

.pgp-key p {
    margin: 0; /* Reset the margin of paragraph elements inside .pgp-key */
}


.pgp-key-content {
    margin-bottom: 20px;
    height: 100%; /* This ensures the pgp-key-content takes the full height */
}



.pgp-key:hover .pgp-key-content {
    visibility: hidden; /* Hide the PGP key content when hovered */
    color: var(--lightest-slate);
}

.spacer{
    height: 10px;
}

.download-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    flex-direction: column;
    color: var(--lightest-slate);
    cursor: pointer;
    width: 100%; /* Ensuring the icon container takes full width */
    height: 100%; /* Ensuring the icon container takes full height */
    background-color: var(--black-bg);
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    color: var(--green);
}

.download-wrapper{
    display: flex;
    align-items: center;
}

.download-icon a {
    text-decoration: none;
    color: var(--slate);
    display: flex;
    align-items: center;
}
.pgp-key:hover .download-icon {
    display: flex; /* Using flex here for easy centering, show the icon on hover */
}

.download-icon img {
    display: block;
    margin: 0 auto 5px;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    filter : brightness(0) invert(1);
}

.fingerprint {
    font-size: 12px;
    font-weight: 400;
    color: var(--light-slate);
    padding-top: 0px;
    margin : 0px 0px 0px 0px;
    margin-bottom: 15px;
    white-space: pre;
}

.pgp-key-link {
    display: inline-block; /* Display as inline-block to avoid taking full width */
    text-decoration: none; /* Remove the default underline */
    color: inherit; /* Inherit the color from parent element */
}



/* Other styles */

.uta-link {
    color: var(--lightest-slate);
    text-decoration: none;
    z-index: 9999;
  }
  
.uta-link:hover {
    color: var(--green);
}
  

.resume-link {
    text-decoration: none;
    color: var(--green);
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: var(--font-mono);
    border: 2px solid var(--green);
    transition: background-color 0.3s ease;

}

.resume-link:hover {
    background-color: rgba(100, 255, 218, 0.1);
}