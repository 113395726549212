.tools-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* six bricks per row */
    gap: 5px;
    align-items: start; /* Align items to start of their cells */
}

.tool-brick {
    background-color: var(--black-bg);
    color: var(--green);
    font-family: monospace;
    text-align: center;
    padding: 20px;
    line-height: 1.2;
    box-sizing: border-box;
    grid-column: span 1;
    /* height: 80px; Set a fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Select and offset odd rows by 50% */
/* .tool-brick:nth-child(12n + 1) {
    grid-column: 4 / span 3; 
} */

.tool-brick:nth-child(12n + 4) {
    grid-column: 1 / span 3; /* Change starting column of the second brick in the odd row */
}
