.experience-item {
    display: flex;
    padding: 0.5em;
    width: 100%;
    margin-bottom: 1em;
    background-color: var(--black-bg);
    backdrop-filter: blur(1.7px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); 
    border-radius: 10px;
    transition: background-color 0.3s ease, border 0.3s ease, transform 0.5s ease; /* Adjust the transform transition duration */
    border: 2px solid transparent;
}

.experience-item:hover {
    background: rgba(255, 255, 255, 0.02);
    border: 2px solid #64FFDA; /* Solid neon cyan outline */
    transform: translate(-3px, -3px); /* Move towards the top-left corner */
    backdrop-filter: blur(1.5px);
}

.left-section {
    flex: 1 1 30%;
    padding: 1em;
    text-align: center;
}

.right-section {
    flex: 1 1 70%;
    padding: 1em;
}

.position {
    margin-top: 0;
    color: var(--lightest-slate);
}

.company {
    margin: 0.5em 0;
    color: var(--light-slate);
}

.description {
    color: var(--slate);
    padding-left: 1em;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
}

.skill {
    color: var(--green);
    padding: 0.5em 1em;
    border: 2px solid rgba(100, 255, 218, 0.6);
    border-radius: 7px;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
}

#experience {
    max-width: min-content;
}

@media screen and (max-width: 768px) {
    .experience-item {
        flex-direction: column; /* This makes the content stack vertically */
    }
    
    .left-section, .right-section {
        flex: 1 1 100%; /* They take the full width */
    }
}
