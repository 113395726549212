.certs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    justify-content: center;
}

.cert-card {
    background-color: var(--black-bg);
    text-align: center;
    border-radius: 10px;
}

.cert-badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
}

.cert-badge {
    max-width: 80%; /* set max-width to 80% of the container */
    max-height: 80%; /* set max-height to 80% of the container */
    object-fit: contain;
}


.cert-text {
    padding: 10px;
}

.view-all-container {
    text-align: center;
    margin-top: 20px;
}

.view-all-button {
    text-decoration: none;
    color: var(--green);
    padding: 10px 20px;
    border-radius: 5px;
    font-family: var(--font-mono);
    border: 2px solid var(--green);
    transition: background-color 0.3s ease;
}

.view-all-button:hover {
    background-color: rgba(100, 255, 218, 0.1);
}
