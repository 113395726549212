.title {
    font-size: 4em;
    text-align: center;
    margin-bottom: 30px;
    color: var(--lightest-slate);
}

h2 {
    font-size: 2em;
}

#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vw;
    margin-bottom: 10vw;
    /* background-color: var(--white-bg); */
    border-radius: 10px;
    margin-top: 10vw;
    margin-bottom: 10vw;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.social-links a {
    color: var(--lightest-slate);
    font-size: 24px;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: var(--green);
}

.contact-content {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.contact-button {
    text-decoration: none;
    color: var(--green);
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: var(--font-mono);
    border: 2px solid var(--green);
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: rgba(100, 255, 218, 0.1);
}

.key-icon {
    width: 24px;
    height: 24px;
}

.social-links a .key-icon {
    color: var(--lightest-slate);
    /* color of svg */
}

.social-links a:hover .key-icon {
    color: var(--green);
    /* color of svg on hover */
}