.section {
    padding: 5vw;
    min-width: 80vw;
    max-width: 87vw;
    margin: 0 auto;
    margin-bottom: 10vw;
    /* margin-left: 10vw;
    margin-right: 10vw; */
    border-radius: 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, backdrop-filter 0.3s ease;
   
}

/* .section:hover { */
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
    /* backdrop-filter: blur(10px); */
/* } */

.sub-item {
    /* position: relative; */
    padding: 1.5rem;
    margin: 0.5rem;
    border-radius: 10px;
    transition: background-color 0.3s ease, border 0.3s ease, transform 0.5s ease; /* Adjust the transform transition duration */
    border: 2px solid transparent;
    /* background-size: 50px 50px; */
    /* background-color: rgba(100, 255, 218, 0.1); */
    background-color: var(--black-bg);
    backdrop-filter: blur(1.7px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);  
    z-index: 1;
    /* border: 2px solid rgba(100,255,218, 0.5); */

}

.sub-item:hover {
    background: rgba(255, 255, 255, 0.02);
    border: 2px solid #64FFDA; /* Solid neon cyan outline */
    transform: translate(-3px, -3px); /* Move towards the top-left corner */
    backdrop-filter: blur(1.5px);
}


.social-links a {
    color: var(--lightest-slate);
    text-decoration: none;
    margin-right: 20px;
}

h2{
    text-align: center;
    margin-bottom: 40px;
}