.navbar {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
    height: 60px;
    display: flex;
    justify-content: space-around;
    font-family: var(--font-mono);
    align-items: center;
    position: fixed;
    top: 0;
    left: 50%; /* center the navbar horizontally */
    transform: translateX(-50%); /* this is needed to perfectly center the navbar */
    transition: top 0.3s;
    z-index: 1000;
    margin-top: 0vw;
    border-radius: 55px;
    min-width: 60%; /* set the width of the navbar */
    max-width: 90%;
}

.navbar a {
    color: var(--lightest-slate);
    text-decoration: none;
    padding: 0.5rem 1.2rem 0.5rem 1.2rem;
    border-radius: 50px;
    font-family: 'SF Mono', 'Calibre','Inter', sans-serif;
    transition: transform 0.3s ease, color 0.3s ease; /* add a transition effect */
    display: flex;
    align-items: center;
}

.navbar a:hover {
    color: #64FFDA;
    background: rgba(255, 255, 255, 0.1);
}

.nav-text {
    display: inline;
}

.nav-icon {
    display: none;
    margin-left: 8px;
}

.hidden {
    display: none;
}


@media (max-width: 768px) {
    .navbar {
      background: transparent;
      backdrop-filter: none;
      border-radius: 0;
      min-width: auto;
      max-width: 100%;
    }
    
    .navbar a {
      padding: 0.5rem;
    }
  }
  
