:root {
--navy: #0a192f;
--light-navy: #112240;
--lightest-navy: #233554;
--slate: #8892b0;
--light-slate: #a8b2d1;
--lightest-slate: #ccd6f6;
--white: #e6f1ff;
--green: #64ffda;
--white-alpha: rgba(255, 255, 255, 0.1);
--white-bg: rgba(255, 255, 255, 0.05);
--black-bg: rgba(0, 0, 0, 0.15);
--shadow: 0 10px 30px -15px var(--navy);
--font-family: 'Inter', sans-serif;
--font-mono: 'JetBrains Mono', monospace;
}

/* This targets the entire scrollbar */
::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-button {
    background-color: #0c132a; /* Color of the scrollbar buttons on the scrollbar */
}

/* This targets the draggable scrolling handle */
::-webkit-scrollbar-thumb {
    background-color: var(--lightest-slate); /* Color of the scroll handle */
    border-radius: 5px; /* Round corners on the scrollbar handle */
}

/* This targets the track or progress bar */
::-webkit-scrollbar-track {
    background-color: #0c132a; /* A dark background for the track */
}


* {
  /* border: 1px solid red; */
  box-sizing: border-box;
}


html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  background-color: var(--navy);
  color: var(--white);
  margin: 0;
  padding: 0;
  position: relative;
  background-attachment: fixed;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, var(--white-bg) 1px, transparent 1px),
                    linear-gradient(to bottom, var(--white-bg) 1px, transparent 1px);
  background-size: 60px 60px;
  pointer-events: none;
}

body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, 
                              #0c132a 0%, 
                              transparent 10%, 
                              transparent 90%, 
                              #0c132a 100%);
  pointer-events: none;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.App {
  background-color: #0b132b; 
  color: var(--white);
  font-family: var(--font-family);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gradient-text {
  background: linear-gradient(135deg, red, blue);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}
